






































import {
  computed,
  defineComponent,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";
import { saveAs } from "file-saver";
import moment from "moment";

export default defineComponent({
  props: {
    posterList: {
      type: Array,
      required: false,
      default: "",
    },
  },
  setup(props, { root, emit }) {
    const state = reactive({
      loading: false,
      loaded: false,
      error: false as boolean | number,
      posterList: [] as any,
    });

    const model = reactive({
      posterStatus: "",
      report: "",
    });

    watch(
      () => props.posterList,
      () => {
        state.posterList = props.posterList.map((item: any) => item["id"]);
      }
    );

    const reportItems = [
      {
        name: root.$tc("panel.event.poster.list.zipArchive"),
        value: "zip",
      },
      {
        name: root.$tc("panel.event.poster.list.xmlFile"),
        value: "xml",
      },
    ];

    const statusItems = [
      {
        name: root.$tc("layout.misc.active"),
        value: "active",
      },
      {
        name: root.$tc("layout.misc.deleted"),
        value: "deleted",
      },
    ];

    const posterIds = computed(() => {
      if (props.posterList && props.posterList.length) {
        const idsArray = props.posterList.map((el: any) => el.id);
        return idsArray;
      } else return [];
    });

    const emitFetch = () => {
      emit("fetch");
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const changeStatus = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        postersIds: state.posterList,
        status: model.posterStatus,
      };

      state.loading = true;

      axiosInstance
        .put("poster/bulk", data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.error = false;

          state.posterList = [];
          model.posterStatus = "";
          emitFetch();

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "success",
            message: root.$tc("layout.misc.participantStatusChanged"),
          });
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    const getReport = (type: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      if (type === "xml") {
        axiosInstance
          .get("poster-report/xml", {
            responseType: "blob",
            params: {
              postersIds: posterIds.value,
            },
          })
          .then((response) => {
            saveAs(response.data, `poster-report.xml`);
          })
          .catch((error) => console.log(error));
      } else {
        const date = moment(new Date()).format("DD-MM-YYYY");
        axiosInstance
          .get("poster-report/compressed", {
            responseType: "blob",
            params: {
              postersIds: posterIds.value,
            },
          })
          .then((response) => {
            saveAs(response.data, `Posters_${date}.zip`);
          })
          .catch((error) => console.log(error));
      }
    };

    return {
      state,
      model,
      reportItems,
      statusItems,
      changeStatus,
      getReport,
      posterIds,
    };
  },
});
